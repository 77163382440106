import React from 'react';
import { Link } from 'react-router-dom';
import '../pages/Home.css';

export default function ({Card}) {
  return (
    <div className='homecard-div'>
         <Link className="underlinenone" to={`/projects/${Card.id}`}> 
        <img src={Card.photo}></img>
        </Link>
        <Link className="underlinenone" to={`/projects/${Card.id}`}> 
        <h2>{Card.name}</h2>
        </Link>
        <p>{Card.text}</p>
    </div>
  )
}
