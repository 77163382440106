import React,{useState} from 'react'
import { Link, useParams} from 'react-router-dom';
import Data from '../Data';
import './Article.css';
import Arrowright from '../img/arrowright.svg';

export default function ProjectOpened() {


    let params = useParams();
    const Card = Data.map(card => {
        if(card.id == params.id)
        {
           return <GetArticle key={card.id} Article={card}/>
        }
    })

    const OtherArticles = Data.map(card => {
           return <OtherArticle key={card.id} Article={card}/>
    })
     

    function GetArticle({Article}){
         return(
             <>
             <img src={Article.photo}></img>
             <h2>{Article.name}</h2>
             <p>{Article.text}</p>
             </>
         )
     }

    function OtherArticle({Article}){
        return(
           
            <div className='other-article'>
               <img src={Article.photo}></img>
            <div className='other-article-content'>
            <Link className='underlinenone' to={`/projects/${Article.id}`}>
               <h2>{Article.name}</h2>
               </Link>
            </div>
            </div>
          
        )
    }



  return (
     <>
   
     <div className='article-flex-between'>
           <div className='article-left'>
              {Card}
           </div>
           <div className='article-right'>
                <span className='other-news'>სხვა პროექტები <img src={Arrowright}></img></span>
                <div className='other-articles'>
                      {OtherArticles}
                </div>
           </div>         
     </div>
     
     
     
     </>
  )
}
