import React from 'react';
import Teamtop from '../img/teamtop.png';
import Teamdata from '../Teamdata';
import Arrowright from '../img/arrowright.svg';

export default function Mission() {


  return (

    <>
      <div className='flex-start'>
        <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  მისია </span> 
        </div>
        <div className='home-hr'></div>
    <div className='team-top'>
        <div className='team-top-left'>
             <label>ჩვენი მისია</label>
             <p>საქართველოს საბანკო ასოციაცია წარმოადგენს არასამეწარმეო (არაკომერციულ) იურიდიულ პირს, რომელიც აერთიანებს საქართელოში მოქმედ საბანკო და საფინანსო-საკრედიტო საქმიანობით დაკავებულ იურიდიულ პირებს, რომელთა საქმიანობა დაკავშირებულია საქართველოს საბანკო სფეროსთან და გამოხატავს საქართველოს საბანკო საზოგადოების ინტერესებს.</p>
        </div>
        <img alt='man-writing' src={Teamtop}></img>
    </div>
    <div className='team-top-text'>
                საქართველოს კომერციული ბანკების თანამშრომლობის განვითარება არარეზიდენტ საბანკო საფინანსო ცენტრებთან.
            საზოგადოების ინფორმირება საქართველოში მოქმედი კომერციული ბანკების საქმიანობის შესახებ და დადებითი საზოგადოებრივი აზრის ფორმირება საბანკო სისტემასთან დაკავშირებით.
            თანამშრომლობის განვითარება სხვადასხვა ბიზნეს სექტორის წარმომადგენლებთან და სხვა ქვეყნების საბანკო ასოციაციებთან.
            საბანკო ასოციაციის დაფუძნებით, საბანკო სექტორის წარმომადგენლები შეთანხმდნენ, რომ უფრო დინამიური გამხდარიყო საბანკო სექტორის დიალოგი საქართველოს ეროვნულ ბანკთან, ქვეყნის მთავრობასთან, საერთაშორისო ორგანიზაციებთან და არასამთავრობო სექტორის წარმომადგენლებთან. 
            ასოციაციის მიზანია, გაერთიანებული ძალისხმევით მოხდეს საკანონმდებლო, ნორმატიული, საცნობარო, სარეკომენდაციო და სამოქმედო გეგმების შეთანხმება, კონსულტაციების გააქტიურება.
            საბანკო ასოციაციის მიზანია საბანკო სექტორის ინტერესების წარმოჩენა და აქტიური დაცვა დასაქმების, გადამზადების, სწავლების და სოციალური პასუხისმგებლობის ხაზგასმის გზით.
            დონორ და პარტნიორ ორგანიზაციებთან თანამშრომლობით, საბანკო ასოციაცია გეგმავს საბანკო საგანმანათლებლო და ერთიანი საბანკო სტანდარტიზაციის მიმართულებით აქტიური ნაბიჯების გადადგმას. დგამს ქმედით ნაბიჯებს სახელმწიფო ორგანიზაციების მრჩევლის და კონსულტანტი ორგანიზაციის იმიჯის დამკვიდრებისა და განმტკიცების მიმართულებით.
            ასოციაცია დაკავებულია საკრედიტო დაწესებულებების ლობირებით მესამე პირებთან მიმართებაში. მიმდინარეობს მოლაპარაკება საქართველოს პარლამენტის მიერ შექმნის პროცესში მყოფ ლობისტთა ერთიან რეესტრში გასაწევრიანებლად.
            ასოციაცია ეწევა ფართო კანონშემოქმედებით საქმიანობას, რაც გამოიხატება დამუშავების პროცესში მყოფ კანონებსა და კანონქვემდებარე აქტებში ცვლილებებისა და შესწორებების შეტანაში. მათი დახვეწის მიზნით, ახდენს წევრი ბანკების რესურსების მობილიზაციას.
            ასევე მუშაობს უკვე დამტკიცებულ ნორმატიულ დოკუმენტებში შესწორებების და დამატებების შეტანის მიმართულებით,  რასაც შემდგომ დასამტკიცებლად წარუდგენს საქართველოს პარლამენტს, მთავრობას, საქართველოს ეროვნულ ბანკსა და ხელისუფლების სხვა მმართველ ორგანოებს.
            ასოციაცია თავის წევრებს უწევს საკონსულტაციო და საინფორმაციო მომსახურებას, არის კონფერენციების, სემინარებისა და სიმპოზიუმების როგორც ორგანიზატორი, ასევე მონაწილეც. ერთვება და ახორციელებს რამდენიმე პროექტს ქვეყნის საბანკო სისტემის გასავითარებლად და მხარდასაჭერად.
            ასოციაცია გეგმავს https://www.ebf.eu/about-us/ ევროპის საბანკო ფედერაციაში გაწევრიანებას, რომელიც ევროპის 3 ათას წევრ ბანკამდე აერთიანებს. ასევე იგეგმება http://www.ibfed.org.uk/ საერთაშორისო საბანკო ფედერაციის ასოცირებული წევრობა, რომლის წევრებიც არიან აშშ-ს, დასავლეთ ევროპის, ავსტრალიის, კანადის, იაპონიის, ჩინეთის, ინდოეთის, სამხრეთ აფრიკის რესპუბლიკის და სამხრეთ კორეის წამყვანი საბანკო დაწესებულებები.
    </div>

    </>
  )
}
