import React, {useState, useEffect} from 'react';
import './OpenBank.css';
import redpdf from '../img/redpdf.svg';
import word from '../img/word.svg';
import web from '../img/web.svg';

export default function Ecosystem({lang}) {
const [Data, setData] = useState([])
const [Toggle, setToggle] = useState(false)

    let get_standards = async () => {
    let response = await fetch('https://openfinance.ge/api/open_banking/eco/')
    let data = await response.json()
    setData(data.data)

    }
    useEffect(() => {
    get_standards()
    },[])

  return (

    <>
    { lang ? 
      <>
            <div className='flex-start margintopstart'>
            <h1 className='open-standart-h1'>ეკოსისტემა</h1>
            </div>
            <div className='flex-start'>
                <p className='font-mkhedr standart-p'>{Data.header}</p>
            </div>
            <div className='home-hr'></div>
            
            <span dangerouslySetInnerHTML={{ __html: Data.body }} ></span>
      </>
      :
      <>
            <div className='flex-start margintopstart'>
            <h1 className='open-standart-h1 fontNoto'>Ecosystem</h1>
            </div>
            <div className='flex-start'>
                <p className='font-mkhedr standart-p'>{Data.header_en}</p>
            </div>
            <div className='home-hr'></div>
            
            <span dangerouslySetInnerHTML={{ __html: Data.body_en }} ></span>
      </>
    }
    </>
  )
}
