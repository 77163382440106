import React, { useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link, useParams } from 'react-router-dom';
import './myCalendar.css';
import './News.css';
import Arrowright from '../img/arrowright.svg';
import HomeCard from '../components/HomeCard';
import Data from '../Data';

export default function News() {
   
  const [value, onChange] = useState(new Date());
  let param = useParams();

    const OtherArticles = Data.map(card => {
      return <OtherArticle key={card.id} Article={card}/>
    })

    const Homecards = Data.map(card => {

      return <HomeCard key={card.id} Card={card} />
    })

  function OtherArticle({Article}){
    return(
        <Link className='underlinenone' to={`/article/${Article.id}`}>
        <div className='other-article'>
           <img src={Article.photo}></img>
        <div className='other-article-content'>
           <h2>{Article.name}</h2>
           <span>{Article.date}</span>
        </div>
        </div>
        </Link>
    )
}


  return (

    <>
         <div className='flex-start'>
        <span className='other-news-home margintop1p5'>მთავარი  <img src={Arrowright}></img> &nbsp;  სიახლეები </span> 
        </div>
        <div className='home-hr'></div>
        <div className='main-layout'>
         <HomeCard key={Data[0].id} Card={Data[0]} />
         <div className='other-articles'>
                      {OtherArticles}
                </div>
        < Calendar onChange={onChange}  value={value}/>
        </div>
        <div className='flex-start'>
        <span className='other-news-home margintop1p5'></span> 
        </div>
        <div className='home-hr'></div>
        <div className='home-grid'>
             {Homecards}
        </div>
    </>
  )
}
