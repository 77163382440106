import React from 'react';
import './Home.css';
import Data from '../Data.js';
import HomeCard from '../components/HomeCard';
import Arrowright from '../img/arrowright.svg';
import { Link, NavLink } from 'react-router-dom';
import ProjectCard from '../components/ProjectCard';

export default function MainProjects() {


  const Homecards = Data.map(card => {

    return <ProjectCard key={card.id} Card={card} />
  })

  return (
         <>
        <div className='flex-start'>
        <span className='other-news-home margintop1p5'>მთავარი  <img src={Arrowright}></img> &nbsp;&nbsp;  პროექტები </span> 
        </div>
        <div className='home-hr'>
        </div>
        <div className='home-grid'>
             {Homecards}
        </div>
        <div className='home-hr'>
        </div>
        <div className='flex-end'>
          <Link className='underlinenone' to='/news'>  <span className='other-news-home'>სხვა სიახლეები <img src={Arrowright}></img></span></Link>
        </div>
        <div className='green-bar'>

        </div>
        </>
  )
}
