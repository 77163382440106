export const Banksdata = 
    [
      {   id:1,
          name:"ლიბერთი ბანკი",
          path:'http://www.association.ge/images/members/6/de7dcc5c59c7c1f362b9f3b9379890a0.jpg',
          cName:'Nav-item',
      },
      {   id:2,
          name:"ვი-თი-ბი ბანკი",
          path:'http://www.association.ge/images/members/7/d873e12e8c6ea060ee9eb16b1bf3d1b8.jpg',
          cName:'Nav-item',
      },
      {   id:3,
          name:"ბანკი ქართუ",
          path:'http://www.association.ge/images/members/8/852980e25872456154bdc4f4b44a990e.jpg',
          cName:'Nav-item',
      }
      ,
      {   id:4,
          name:"პროკრედიტ ბანკი",
          path:'http://www.association.ge/images/members/9/32d9812afc5ae4439492116dd0dcf4bd.jpg',
          cName:'Nav-item',
      }
      ,
      {   id:5,
          name:"პაშა ბანკი საქართველო",
          path:'http://www.association.ge/images/members/11/25c98cf9b1cb0e76f09a69866094aefe.jpg',
          cName:'Nav-item',
      }
      ,
      {   id:6,
          name:"ხალიკ ბანკი საქართველო",
          path:'http://www.association.ge/images/members/12/dd8e8b9ae54f05d1ea292febf6d8b415.jpg',
          cName:'Nav-item',
      }
    ]