import {useState,useEffect} from 'react';
import Teamtop from '../img/teamtop.png';
import './Banks.css';

import Arrowright from '../img/arrowright.svg';
import { Helmet } from 'react-helmet-async';

export default function OpenBanking({lang}) {
    const [Data, setData] = useState([])


    let getData = async () => {
        let response = await fetch('https://openfinance.ge/api/open_banking/governance/')
        let data = await response.json()
        
        setData(data.data)
    }

    useEffect(() => {
        getData()   
    },[])

    useEffect(() => {
        getData()   
    },[lang])

    
    const deviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
           return false;
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
           return false;
        }
        return true;
     };

  if( Data.length == 0){
    return <div style={{height:'50vh',width:'99vw'}}>
             
         </div>
   }  
 
    return (

        <>
        {
                lang ? 
                <Helmet>
                <title>მმართველობა - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content="საქართველოს საბანკო ასოციაციის მმართველობა" data-react-helmet="true" />
                <meta property="og:title" content="მმართველობა - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta property="og:description" content="საქართველოს საბანკო ასოციაციის მმართველობა" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/about-us/governance" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="მმართველობა - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta name="twitter:description" content="საქართველოს საბანკო ასოციაციის მმართველობა" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/about-us/governance" data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>Governance -Banking Association of Georgia</title>
                <meta name="description" content="Governance of the Banking Association of Georgia" data-react-helmet="true" />
                <meta property="og:title" content="Governance -Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:description" content="Governance of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/en/about-us/governance" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="Governance -Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:description" content="Governance of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/en/about-us/governance" data-react-helmet="true"/>
              </Helmet>
              }
        { lang ? 
                  ( deviceType() ? 
                //desktop
                  <>
                  <div className='flex-start margintopstart'>
                    <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  მმართველობა </span> 
                    </div>
                    <div className='home-hr'></div>
                    <div className='team-top'>
                        <div className='team-top-left'>
                            <label>მმართველობა</label>
                            <p>{Data.header}</p> 
                        </div>
                        <img alt='man-writing' src={Data.photo}></img>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:Data.body }} className='team-top-text'></div>
                    </>
                  :
                  //mobile
                  <>
                    <div className='flex-start margintopstart'>
                        <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  მმართველობა </span> 
                        </div>
                        <div className='home-hr'></div>
                    <div className='team-top'>
                            <label>მმართველობა</label>
                            <img alt='man-writing' src={Data.photo}></img>
                            <p className='team-top-leftp'>{Data.header}</p> 
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:Data.body }} className='team-top-text'></div>
                    </>
                    )
                    :
                    ( deviceType() ? 
                //desktop
                <>
                <div className='flex-start margintopstart'>
                    <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp; Governance </span> 
                    </div>
                    <div className='home-hr'></div>
                    <div className='team-top'>
                        <div className='team-top-left'>
                            <label>Governance</label>
                            <p>{Data.header_en}</p> 
                        </div>
                        <img alt='man-writing' src={Data.photo}></img>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:Data.body_en }} className='team-top-text'></div>
                    </>
                :
                //mobile
                <>
                    <div className='flex-start margintopstart'>
                        <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  Governance </span> 
                        </div>
                        <div className='home-hr'></div>
                    <div className='team-top'>
                            <label>Governance</label>
                            <img alt='man-writing' src={Data.photo}></img>
                            <p className='team-top-leftp'>{Data.header_en}</p> 
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:Data.body_en }} className='team-top-text'></div>
                    </>
                    )

                  }
      
        </>
      )
  }
  

