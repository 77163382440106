export const navdata = 
    [
      {   id:1,
          name:"სიახლეები",
          name_eng:"NEWS",
          path:'/',
          cName:'Nav-item',
          sublist:[
            ]
      },
      {   id:2,
          name:"სტანდარტები",
          name_eng:"STANDARTS",
          path:'/standards',
          cName:'Nav-item',
          sublist:[
          ]
      },
      {   id:3,
          name:"ეკოსისტემა",
          name_eng:"ECOSYSTEM",
          path:'/ecosystem',
          cName:'Nav-item',
          sublist:[
          ]
      }
      ,
      {   id:4,
          name:"ჩვენს შესახებ",
          name_eng:"ABOUT US",
          path:'/aboutus',
          cName:'Nav-item',
          sublist:[
            { subid:1,
              name:'ღია ბანკინგი',
              name_eng:"OPEN BANKING",
              path:'/aboutus/openbanking'
            }, 
            { subid:2,
              name:'მმართველობა',
              name_eng:"GOVERNANCE",
              path:'/aboutus/governance'
            },
            { subid:3,
              name:'წევრები',
              name_eng:"MEMBERS",
              path:'/aboutus/members'
            }
          ]
      }
      ,
      {   id:5,
          name:"ჩაერთე",
          name_eng:"JOIN",
          path:'/join',
          cName:'Nav-item',
          sublist:[
           
          ]
      }
      ,
      {   id:6,
          name:"კონტაქტი",
          name_eng:"CONTACT",
          path:'/contact',
          cName:'Nav-item',
          sublist:[
           
          ]
      }
    ]