import './App.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import { Fragment, useState ,useEffect } from 'react';
import Footer from './components/Footer';
import Article from './pages/Article';
import Team from './pages/Team';
import News from './pages/News';
import NewsOpened from './pages/NewsOpened';
import ProjectOpened from './pages/ProjectOpened';
import Banks from './pages/Banks';
import MainProjects from './pages/MainProjects';
import Committees from './pages/Committees';
import CommitteeOpened from './pages/CommitteeOpened';
import Mission from './pages/Mission';
import Partners from './pages/Partners';
import Banking from './pages/Banking';
import Contact from './pages/Contact';
import Finances from './pages/Finances';
import Standards from './pages/Standards';
import Join from './pages/Join';
import Ecosystem from './pages/Ecosystem';
import OpenBanking from './pages/OpenBanking';

function App() {

  var WebFont = require('webfontloader');
  WebFont.load({
    custom: {
      families: ['BPG Nino Mkhedruli','BPG Nino Mtavruli','Noto Sans Display'],
      urls: ['index.css']
    }
  });

  
  
   
  const [Lang,setLang] = useState(true);
  const LangFunction = (bool) => {
    if( bool == true ){
      setLang(true)
    }
    else{
      setLang(false)
    }
}  

  useEffect(() => {
    if ( window.location.pathname.includes('/en') ) 
     setLang(false)
   }, [])
   
  return (

    <BrowserRouter>
        <div className="main">
        <ScrollToTop/>
        <Fragment>
        <Navbar langfun={LangFunction} lang={Lang} />
        <Routes>
           <Route path='/' element={<News  lang={Lang} />} /> 
           <Route path='/:id' element={<News  lang={Lang} />} />  
           <Route path='/projects' element={<MainProjects  lang={Lang} />} />  
           <Route path='/projects/:id' element={<ProjectOpened  lang={Lang} />} />  
           <Route path='/news/:id' element={< NewsOpened  lang={Lang} />} />        
           <Route path='/article/:id' element={<Article  lang={Lang} />}/>
           <Route path='/aboutus/governance' element={<Team  lang={Lang} />}/>
           <Route path='/aboutus/openbanking' element={<OpenBanking lang={Lang} />}/>
           <Route path='/aboutus/members' element={<Banking lang={Lang} />}/>
           <Route path='/aboutus/mission' element={< Mission  lang={Lang} />}/>
           <Route path='/aboutus/partners' element={< Partners  lang={Lang} />}/>
           <Route path='/contact' element={<Contact  lang={Lang} />}/>
           <Route path='/ecosystem' element={<Ecosystem  lang={Lang} />}/>
           <Route path='/standards' element={< Standards  lang={Lang} />}/>
           <Route path='/join' element={<Join  lang={Lang} />}/>

           <Route path='/en' element={<News  lang={Lang} />} /> 
           <Route path='/en/:id' element={<News  lang={Lang} />} />  
           <Route path='/en/projects' element={<MainProjects  lang={Lang} />} />  
           <Route path='/en/projects/:id' element={<ProjectOpened  lang={Lang} />} />  
           <Route path='/en/news/:id' element={< NewsOpened  lang={Lang} />} />        
           <Route path='/en/article/:id' element={<Article  lang={Lang} />}/>
           <Route path='/en/aboutus/governance' element={<Team  lang={Lang} />}/>
           <Route path='/en/aboutus/openbanking' element={<OpenBanking lang={Lang} />}/>
           <Route path='/en/aboutus/members' element={<Banking lang={Lang} />}/>
           <Route path='/en/aboutus/mission' element={< Mission  lang={Lang} />}/>
           <Route path='/en/aboutus/partners' element={< Partners  lang={Lang} />}/>
           <Route path='/en/contact' element={<Contact  lang={Lang} />}/>
           <Route path='/en/ecosystem' element={<Ecosystem  lang={Lang} />}/>
           <Route path='/en/standards' element={< Standards  lang={Lang} />}/>
           <Route path='/en/join' element={<Join  lang={Lang} />}/>
        </Routes> 
        </Fragment>
        <Footer lang={Lang}/>
       </div>
    </BrowserRouter>
    
  );
}

export default App;
