import React,{useState, useEffect} from 'react'
import { Link, useParams} from 'react-router-dom';
import Data from '../Data';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './myCalendar.css';
import './Article.css';
import Arrowright from '../img/arrowright.svg';
import { Helmet } from "react-helmet-async";

export default function Article({lang}) {

    const [value, onChange] = useState(new Date());
    const [Data, setData] = useState([])
    const [Articles, setArticles] = useState([])
    const [Toggle, setToggle] = useState(false)
    const [translatecat,settranslatecat] = useState('')
    let params = useParams();

    let getarticles = async () => {
    
        let  requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                  'url_title': params.id   
             })
        };
        let response = await fetch('https://openfinance.ge/api/open_banking/article/',requestOptions )
        let data = await response.json()
        setToggle(true)
        setData(data.article)
        setArticles(data.articles)
        onChange(new Date(data.article.date))
        return data['article']
      }
    
      useEffect(() => {
        getarticles()
        translatecategory(params)
      },[params])

      useEffect(() => {
         translatecategory(params)
       },[lang])

    const Card = ({Data}) => {
        return <GetArticle key={Data} Article={Data}/>
    }
        

    const OtherArticles = ({Articles}) => Articles.map(card => {
        return <OtherArticle key={card.id} Article={card}/>
    })

    const OtherArticlesMobile = ({Articles}) => Articles.slice(0,2).map(card => {
        return <OtherArticle key={card.id} Article={card}/>
    })

    function translatecategory(params){
      if (lang){
        if(params.id == 'news')
        settranslatecat('სიახლეები')
        else if (params.id == 'events')
        settranslatecat('ღონისძიებები')
        else if (params.id == 'researches')
        settranslatecat('კვლევები')
        else if (params.id == 'socialresponsibility')
        settranslatecat('სოციალური პასუხისმგებლობა')
      }
      else if(params.id == 'socialresponsibility')
        settranslatecat('social responsibility')
      else
        settranslatecat(params.id)
 }
        
      
     

    function GetArticle({Article}){
         return(
             <>
               {  lang ?
                   <>
                     <img itemProp='image' src={Article.photo}></img>
                     <h1 itemProp='headline'>{Article.title}</h1>
                     <span itemProp='datePublished'>{Article.date}</span>
                     <h2 itemProp='description' dangerouslySetInnerHTML={{ __html: Article.header }} ></h2>
                     <p itemProp='articleBody' dangerouslySetInnerHTML={{ __html: Article.body }} ></p>
                   </>
                   :
                   <>
                     <img itemProp='image' src={Article.photo}></img>
                     <h1 itemProp='headline'>{Article.title_en}</h1>
                     <span itemProp='datePublished'>{Article.date}</span>
                     <h2 itemProp='description' dangerouslySetInnerHTML={{ __html: Article.header_en }} ></h2>
                     <p itemProp='articleBody' dangerouslySetInnerHTML={{ __html: Article.body_en }} ></p>
                   </>
               }
             </>
         )
     }

     const months = ["იანვარი","თებერვალი","მარტი","აპრილი","მაისი","ივნისი","ივლისი","აგვისტო","სექტემბერი","ოქტომბერი","ნოემბერი","დეკემბერი"]
     const days = ["კვი","ორშ","სამ","ოთხ","ხუთ","პარ","შაბ"]

    function OtherArticle({Article}){
        return(
            <>
               { lang ?
                    <div className='other-article'>
                    <img src={Article.photo}></img>
                    <div className='other-article-content'>
                    <Link className='underlinenone' to={`/article/${Article.url_title}`}>
                    <h2>{Article.title}</h2>
                    </Link>
                    <span>{Article.date}</span>
                    </div>
                    </div>
                 :
                     <div className='other-article'>
                     <img src={Article.photo}></img>
                     <div className='other-article-content'>
                     <Link className='underlinenone' to={`/article/${Article.url_title}`}>
                     <h2>{Article.title_en}</h2>
                     </Link>
                     <span>{Article.date}</span>
                     </div>
                     </div>
               }
            </>
          
        )
    }

    const deviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
           return false;
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
           return false;
        }
        return true;
     };



  return (
       <>
              	<Helmet>
                <title></title>
                <meta name="description" content="Georgian Banking Association" data-react-helmet="true" />
                <meta property="og:title" content="Georgian Banking Association" data-react-helmet="true"/>
                <meta property="og:description" content="Georgian Banking Association" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="Georgian Banking Association" data-react-helmet="true"/>
                <meta name="twitter:description" content="Georgian Banking Association" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/" data-react-helmet="true"/>
              </Helmet>
       { lang ?
            ( !deviceType() ?
            //mobile
            <>
            <div className='flex-start margintopstart'>
            <span className='other-news-home margintop1p5'><Link className='underlinenone defaulta' to='/'>სიახლეები</Link>  
            <img src={Arrowright}></img> &nbsp;&nbsp;  <span className='mobile-link-tag'>{Data.title}</span></span>
            </div>
            <div className='home-hr'></div>
            <div className='article-flex-between'>
               <div itemScope itemType="https://schema.org/Article" className='article-left'>
                  <Card Data={Data} />
               </div>
               <div className='article-right'>
                    <span className='other-news'>სხვა სიახლეები <img src={Arrowright}></img></span>
                    <div className='other-articles'>
                          <OtherArticlesMobile Articles={Articles} />
                    </div>
                    {/* < Calendar locale="ka" onChange={onChange}  value={value}/> */}
               </div>         
            </div>
            </>
            :
            //desktop
            <>
            <div className='flex-start margintopstart'>
            <span className='other-news-home margintop1p5'><Link className='underlinenone defaulta' to='/'>სიახლეები</Link>
            <img src={Arrowright}></img> &nbsp;&nbsp; {Data.title}</span> 
            </div>
            <div className='home-hr'></div>
            <div className='article-flex-between'>
               <div itemScope itemType="https://schema.org/Article" className='article-left'>
                  <Card Data={Data} />
               </div>
               <div className='article-right'>
                    <span className='other-news'>სხვა სიახლეები <img src={Arrowright}></img></span>
                    <div className='other-articles'>
                          <OtherArticles Articles={Articles} />
                    </div>
                    < Calendar formatMonth={(locale,date) => {return months[date.getMonth()]}}
         formatShortWeekday={(locale,date) => {return days[date.getDay()]}}
         formatMonthYear={(locale,date) => {return months[date.getMonth()]+' '+date.getFullYear()}} onChange={onChange}  value={value}/>
               </div>         
            </div>
            </>
            )
          :
            ( !deviceType() ?
            //mobile
            <>
            <div className='flex-start margintopstart'>
            <span className='other-news-home margintop1p5 fontNoto'><Link className='underlinenone defaulta' to='/en'>news</Link>
            <img src={Arrowright}></img> &nbsp;&nbsp;  <span className='mobile-link-tag'>{Data.title_en}</span></span> 
            </div>
            <div className='home-hr'></div>
            <div className='article-flex-between'>
               <div itemScope itemType="https://schema.org/Article" className='article-left'>
                  <Card Data={Data} />
               </div>
               <div className='article-right'>
                     <span className='other-news'>Other news<img src={Arrowright}></img></span>
                     <div className='other-articles'>
                           <OtherArticlesMobile Articles={Articles} />
                     </div>
                     {/* < Calendar locale="ka" onChange={onChange}  value={value}/> */}
               </div>         
            </div>
            </>
            :
            //desktop
            <>
            <div className='flex-start margintopstart'>
            <span className='other-news-home margintop1p5 fontNoto'><Link className='underlinenone defaulta' to='/en'>news</Link>
            <img src={Arrowright}></img> &nbsp;&nbsp;  <span className='mobile-link-tag'>{Data.title_en}</span></span>
            </div>
            <div className='home-hr'></div>
            <div className='article-flex-between'>
               <div itemScope itemType="https://schema.org/Article" className='article-left'>
                  <Card Data={Data} />
               </div>
               <div className='article-right'>
                     <span className='other-news'>Other news<img src={Arrowright}></img></span>
                     <div className='other-articles'>
                           <OtherArticles Articles={Articles} />
                     </div>
                     < Calendar locale={"KA-ka"} value={value}/>
               </div>         
            </div>
            </>
          )
       }
     </>
  )
}
