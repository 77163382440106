import {useState, useEffect} from 'react';
import './Join.css';

export default function Join({lang}) {
	const [Data,setData] = useState([])
	const [Toggle,setToggle] = useState([])



	let getData = async  () => {

		let response = await fetch('https://openfinance.ge/api/open_banking/join/')
		let data = await response.json()
		
		setToggle(true)
		setData(data.data)

		return data['data']
		}

	useEffect(() => {
		
		getData()
		
		},[])

	return (
	<>
		{
			lang ? 
			<>
			<div className='join-fixed'>
			<span>ჩაერთე</span>
			<div className='join-hr'></div>
			</div>
			<div className='join-menu-fixed'>
				{
					Data.map(join => {
						return <a key={join.id} href={`#${join.id}`} > - {join.title}</a>
					} )
				}
				
				
			</div>
			<div>
			{
				Data.map(join => {
					return <div key={join.id} id={join.id} className='join-topic margintopbig'>
					<h2>{join.title}</h2>
					<p dangerouslySetInnerHTML={{__html:join.body}} ></p>
					</div>
				})
			}
			</div>
			</>
			:
			<>
			<div className='join-fixed'>
			<span className='fontNoto'>Join</span>
			<div className='join-hr'></div>
			</div>
			<div className='join-menu-fixed'>
				{
					Data.map(join => {
						return <a key={join.id} href={`#${join.id}`} > - {join.title_en}</a>
					} )
				}
				
				
			</div>
			<div>
			{
				Data.map(join => {
					return <div key={join.id} id={join.id} className='join-topic margintopbig'>
					<h2>{join.title_en}</h2>
					<p dangerouslySetInnerHTML={{__html:join.body_en}} ></p>
					</div>
				})
			}
			</div>
			</>
		}
	</>
	)
}

