import { useState, useEffect } from 'react';
import {Banksdata} from '../Bankdata';
import { Link } from 'react-router-dom';
import Arrowright from '../img/arrowright.svg';
import './Banks.css';
import { Helmet } from 'react-helmet-async';

export default function Banks({lang}) {
  const [Banksdata, setData] = useState([])
  const [Toggle, setToggle] = useState(false)

  let getbanks = async () => {
    let response = await fetch('https://openfinance.ge/api/open_banking/contributors/')
    let data = await response.json()
    setData(data.data)
  }

  useEffect(() => {
    getbanks()
  },[])

  

  function BankCard({bank}){

      const [ShowBank,SetShowBank] = useState(false);

          return (
              <>
              {
                lang ? 
                <Helmet>
                <title>წევრები - საქართველოს საბანკო ასოციაცია</title>
                <meta name="description" content="საქართველოს საბანკო ასოციაციის წევრი ბანკები" data-react-helmet="true" />
                <meta property="og:title" content="ბანკები - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta property="og:description" content="საქართველოს საბანკო ასოციაციის წევრი ბანკები" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/members/banks" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="ბანკები - საქართველოს საბანკო ასოციაცია" data-react-helmet="true"/>
                <meta name="twitter:description" content="საქართველოს საბანკო ასოციაციის წევრი ბანკები" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/members/banks" data-react-helmet="true"/>
              </Helmet>
              :
              <Helmet>
                <title>Members - Banking Association of Georgia</title>
                <meta name="description" content="Member banks of the Banking Association of Georgia" data-react-helmet="true" />
                <meta property="og:title" content="Banks - Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:description" content="Member banks of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:site_name" content="association" data-react-helmet="true"/>
                <meta property="og:url" content="https://www.association.ge/en/members/banks" data-react-helmet="true" />
                <meta property="og:image" content="https://www.association.ge/" data-react-helmet="true"/>
                <meta name="twitter:title" content="Banks - Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:description" content="Member banks of the Banking Association of Georgia" data-react-helmet="true"/>
                <meta name="twitter:image" content="" data-react-helmet="true"/>
                <meta name="twitter:image:alt" content="" data-react-helmet="true"/>
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <link rel="canonical" href="https://www.association.ge/en/members/banks" data-react-helmet="true"/>
              </Helmet>
              }
              { lang ?
                   <>
                   <div className='bank-card' onClick={() => SetShowBank(!ShowBank) }>
                       <img src={bank.photo}></img>
                       <h2>{bank.name}</h2>
                   </div>
                   <div onClick={() => {{ShowBank? SetShowBank(false) : SetShowBank(ShowBank)}}} 
                   className={`${ShowBank? 'bank-modal-outer' : 'none'}`}>
                       <div className='bank-modal'>
                         <img src={bank.photo}></img>
                         <h2>{bank.name}</h2>
                         <p dangerouslySetInnerHTML={{ __html:bank.description }} className='fontNoto'></p>
                         {/* <a className='underlinenone' target='_blank' href={bank.contact_info}> საკონტაქტო ინფორმაცია <span className='other-news-home'></span></a>
                         <a className='underlinenone' target='_blank' href={bank.corporate_gov}> კორპორატიული მმართველობა <span className='other-news-home'></span></a> */}
                       </div>
                   </div>
                 </>
                 :
                  <>
                  <div className='bank-card' onClick={() => SetShowBank(!ShowBank) }>
                      <img src={bank.photo}></img>
                      <h2 className='fontNoto'>{bank.name_en}</h2>
                  </div>
                  <div onClick={() => {{ShowBank? SetShowBank(false) : SetShowBank(ShowBank)}}} 
                  className={`${ShowBank? 'bank-modal-outer' : 'none'}`}>
                      <div className='bank-modal'>
                        <img src={bank.photo}></img>
                        <h2 className='fontNoto'>{bank.name_en}</h2>
                        <p dangerouslySetInnerHTML={{ __html:bank.description_en }} className='fontNoto'></p>
                        {/* <a className='underlinenone fontNoto' target='_blank' href={bank.contact_info}> Contact Infotmation <span className='other-news-home'></span></a>
                        <a className='underlinenone fontNoto' target='_blank' href={bank.corporate_gov}> Corporate Governance <span className='other-news-home'></span></a> */}
                      </div>
                  </div>
                </>
              }
              </>
          )
    }

  return (
    <>
    { lang ?
        <>
        <div className='flex-start margintopstart'>
          <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  წევრები </span> 
          </div>
          <div className='home-hr'></div>
      <div className='banks-grid '>
        {
          Banksdata.map(bank => {
            return <BankCard key={bank.id} bank={bank} />})
        }
      </div>
      </>
      :
      <>
      <div className='flex-start margintopstart'>
          <span className='other-news-home margintop1p5 fontNoto'>About us  <img src={Arrowright}></img> &nbsp;&nbsp;  Members </span> 
          </div>
          <div className='home-hr'></div>
      <div className='banks-grid '>
        {
          Banksdata.map(bank => {
            return <BankCard key={bank.id} bank={bank} />})
        }
      </div>
      </>
    }
    </>
    
  )
}
