import React, { useState } from 'react';
import {Banksdata} from '../Bankdata';
import './Banks.css';
import Arrowright from '../img/arrowright.svg';

export default function Partners() {

    const banks = Banksdata.map(bank => {
        return <BankCard key={bank.id} bank={bank} />})

    function BankCard({bank}){

        const [ShowBank,SetShowBank] = useState(false);

            return (
                <>
                  <div className='bank-card' onClick={() => SetShowBank(!ShowBank) }>
                      <img src={bank.path}></img>
                      <h2>{bank.name}</h2>
                  </div>
                  <div onClick={() => {{ShowBank? SetShowBank(false) : SetShowBank(ShowBank)}}} 
                  className={`${ShowBank? 'bank-modal-outer' : 'none'}`}>
                     <div className='bank-modal'>
                        <img src={bank.path}></img>
                        <h2>{bank.name}</h2>
                     </div>
                  </div>
                </>
            )
    }

  return (
      <>
       <div className='flex-start margintopstart'>
        <span className='other-news-home margintop1p5'>ჩვენს შესახებ  <img src={Arrowright}></img> &nbsp;&nbsp;  პარტნიორები </span> 
        </div>
        <div className='home-hr'></div>
        <div className='flex-start'>
        <span className='other-news-home margintop1p5'>საერთაშორისო თანამშრომლობა</span> 
        </div>
        <div className='banks-grid'>
           {banks}
    </div>
      </>

   
  )
}
