import React, {useState, useEffect} from 'react';
import './OpenBank.css';
import redpdf from '../img/redpdf.svg';
import word from '../img/word.svg';
import web from '../img/web.svg';

export default function Standarts({lang}) {
const [Data, setData] = useState([])
const [Toggle, setToggle] = useState(false)

let get_standards = async () => {
let response = await fetch('/api/open_banking/standards/')
let data = await response.json()
setData(data.standards)

}
useEffect(() => {
get_standards()
},[])

const Standards = ({Data}) => 
      Data.map(standard => {
      
            return ( 
                lang ? 
                <div className='text-pdf-flex'>
                        <div className='text-pdf-col'>
                              <span className='text-pdf-header'>{standard.title}</span>
                              <span className='text-pdf-date'>{standard.date}</span>
                              <p className='text-pdf-p'>{standard.header}</p>
                        </div>
                        <div className='pdf-content-flex'>
                        
                              {
                                    standard.pdf ? <a target="_blank" href={standard.pdf}><img src={redpdf}></img></a>
                                          : <a target="_blank" href=""><img src={redpdf}></img></a>
                              }

                              {
                                    standard.word ? <a target="_blank" href={standard.word}><img src={word}></img></a>
                                          : <a target="_blank" href=""><img src={word}></img></a>
                              }

                              {
                                    standard.web ? <a target="_blank" href={standard.web}><img src={web}></img></a>
                                          : <a target="_blank" href=""><img src={web}></img></a>
                              }
                              
                        
                        </div>
                  </div> 
                  :
                  <div className='text-pdf-flex'>
                        <div className='text-pdf-col'>
                              <span className='text-pdf-header'>{standard.title_en}</span>
                              <span className='text-pdf-date'>{standard.date}</span>
                              <p className='text-pdf-p'>{standard.header_en}</p>
                        </div>
                        <div className='pdf-content-flex'>
                        
                              {
                                    standard.pdf ? <a target="_blank" href={standard.pdf}><img src={redpdf}></img></a>
                                          : <a target="_blank" href=""><img src={redpdf}></img></a>
                              }

                              {
                                    standard.word ? <a target="_blank" href={standard.word}><img src={word}></img></a>
                                          : <a target="_blank" href=""><img src={word}></img></a>
                              }

                              {
                                    standard.web ? <a target="_blank" href={standard.web}><img src={web}></img></a>
                                          : <a target="_blank" href=""><img src={web}></img></a>
                              }
                              
                        
                        </div>
                  </div> 
            )
      }) 

  return (

    <>
     {
      lang ? 
       <>
       <div className='flex-start margintopstart'>
           <h1 className='open-standart-h1'>საქართველოს ღია ბანკინგის სტანდარტი</h1>
            </div>
            <div className='flex-start'>
                  <p className='font-mkhedr standart-p'>საქართველოს ღია ბანკინგის სტანდარტის მოქმედი ვერსია შეგიძლიათ ჩამოტვირთოთ PDF და Microsoft Word ფორმატში. გთხოვთ, თქვენი შენიშვნები და წინადადებები ღია ბანკინგის სტანდარტთან დაკავშირებით გამოგვიგზავნოთ საქართველოს საბანკო ასოციაციაში, ელფოსტის მისამართზე <a className='mail' href="mailto:obstandards@association.ge">obstandards@association.ge</a></p>
            </div>
            <div className='home-hr'></div>
            
            <Standards Data={Data}/>
       </>
       :
       <>
       <div className='flex-start margintopstart'>
           <h1 className='open-standart-h1 fontNoto'>Georgian Open Banking Standart</h1>
            </div>
            <div className='flex-start'>
                  <p className='fontNoto standart-p'>საქართველოს ღია ბანკინგის სტანდარტის მოქმედი ვერსია შეგიძლიათ ჩამოტვირთოთ PDF და Microsoft Word ფორმატში. გთხოვთ, თქვენი შენიშვნები და წინადადებები ღია ბანკინგის სტანდარტთან დაკავშირებით გამოგვიგზავნოთ საქართველოს საბანკო ასოციაციაში, ელფოსტის მისამართზე <a className='mail' href="mailto:obstandards@association.ge">obstandards@association.ge</a></p>
            </div>
            <div className='home-hr'></div>
      
      <Standards Data={Data}/>
       </>
     }
    </>
  )
}
